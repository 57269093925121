import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "print-format-main",
    style: _normalizeStyle($setup.rootStyles)
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle($setup.page_number_style)
    }, _toDisplayString(_ctx.__("1 of 2")), 5 /* TEXT, STYLE */),
    _createVNode($setup["LetterHeadEditor"], { type: "Header" }),
    _createVNode($setup["HTMLEditor"], {
      value: $setup.layout.header,
      onChange: _cache[0] || (_cache[0] = $event => ($setup.layout.header = $event)),
      "button-label": _ctx.__('Edit Header')
    }, null, 8 /* PROPS */, ["value", "button-label"]),
    _createVNode($setup["draggable"], {
      class: "mb-4",
      modelValue: $setup.layout.sections,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.layout.sections) = $event)),
      group: "sections",
      filter: ".section-columns, .column, .field",
      animation: 200,
      "item-key": "id"
    }, {
      item: _withCtx(({ element }) => [
        _createVNode($setup["PrintFormatSection"], {
          section: element,
          onAdd_section_above: $event => ($setup.add_section_above(element))
        }, null, 8 /* PROPS */, ["section", "onAdd_section_above"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode($setup["HTMLEditor"], {
      value: $setup.layout.footer,
      onChange: _cache[2] || (_cache[2] = $event => ($setup.layout.footer = $event)),
      "button-label": _ctx.__('Edit Footer')
    }, null, 8 /* PROPS */, ["value", "button-label"]),
    ($setup.letterhead)
      ? (_openBlock(), _createBlock($setup["HTMLEditor"], {
          key: 0,
          value: $setup.letterhead.footer,
          onChange: $setup.update_letterhead_footer,
          "button-label": _ctx.__('Edit Letter Head Footer')
        }, null, 8 /* PROPS */, ["value", "button-label"]))
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}