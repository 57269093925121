import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e7c255b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-side-section" }
const _hoisted_2 = { class: "form-sidebar" }
const _hoisted_3 = { class: "sidebar-menu" }
const _hoisted_4 = { class: "sidebar-label" }
const _hoisted_5 = { class: "margin-controls" }
const _hoisted_6 = { class: "clearfix" }
const _hoisted_7 = { class: "control-label" }
const _hoisted_8 = { class: "control-input-wrapper" }
const _hoisted_9 = { class: "control-input" }
const _hoisted_10 = ["value", "onChange"]
const _hoisted_11 = { class: "sidebar-menu" }
const _hoisted_12 = { class: "sidebar-label" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "control-input-wrapper" }
const _hoisted_15 = { class: "control-input" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "sidebar-menu" }
const _hoisted_18 = { class: "sidebar-label" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "control-input-wrapper" }
const _hoisted_21 = { class: "control-input" }
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "sidebar-menu" }
const _hoisted_24 = { class: "sidebar-label" }
const _hoisted_25 = { class: "form-group" }
const _hoisted_26 = { class: "control-input-wrapper" }
const _hoisted_27 = { class: "control-input" }
const _hoisted_28 = ["value"]
const _hoisted_29 = { class: "sidebar-menu" }
const _hoisted_30 = { class: "sidebar-label" }
const _hoisted_31 = ["placeholder"]
const _hoisted_32 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.__("Page Margins")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.margins, (df) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "form-group",
              key: df.fieldname
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("label", _hoisted_7, _toDisplayString(df.label), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("input", {
                    type: "number",
                    class: "form-control form-control-sm",
                    value: $setup.print_format[df.fieldname],
                    min: "0",
                    onChange: (e) => $setup.update_margin(df.fieldname, e.target.value)
                  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_10)
                ])
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.__("Google Font")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _withDirectives(_createElementVNode("select", {
                class: "form-control form-control-sm",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.print_format.font) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.google_fonts, (font) => {
                  return (_openBlock(), _createElementBlock("option", { value: font }, _toDisplayString(font), 9 /* TEXT, PROPS */, _hoisted_16))
                }), 256 /* UNKEYED_FRAGMENT */))
              ], 512 /* NEED_PATCH */), [
                [_vModelSelect, $setup.print_format.font]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.__("Font Size")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("input", {
                type: "number",
                class: "form-control form-control-sm",
                placeholder: "12, 13, 14",
                value: $setup.print_format.font_size,
                onChange: _cache[1] || (_cache[1] = 
									(e) => ($setup.print_format.font_size = parseFloat(e.target.value))
								)
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_22)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.__("Page Number")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _withDirectives(_createElementVNode("select", {
                class: "form-control form-control-sm",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.print_format.page_number) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.page_number_positions, (position) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: position.value
                  }, _toDisplayString(position.label), 9 /* TEXT, PROPS */, _hoisted_28))
                }), 256 /* UNKEYED_FRAGMENT */))
              ], 512 /* NEED_PATCH */), [
                [_vModelSelect, $setup.print_format.page_number]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.__("Fields")), 1 /* TEXT */),
        _withDirectives(_createElementVNode("input", {
          class: "mb-2 form-control form-control-sm",
          type: "text",
          placeholder: _ctx.__('Search fields'),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.search_text) = $event))
        }, null, 8 /* PROPS */, _hoisted_31), [
          [_vModelText, $setup.search_text]
        ]),
        _createVNode($setup["draggable"], {
          class: "fields-container",
          list: $setup.fields,
          group: { name: 'fields', pull: 'clone', put: false },
          sort: false,
          clone: $setup.clone_field,
          "item-key": "id"
        }, {
          item: _withCtx(({ element }) => [
            _createElementVNode("div", {
              class: "field",
              title: element.fieldname
            }, _toDisplayString(element.label), 9 /* TEXT, PROPS */, _hoisted_32)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["list"])
      ])
    ])
  ]))
}