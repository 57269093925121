import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f982735c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "letterhead" }
const _hoisted_2 = { class: "mb-4 d-flex justify-content-between" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "btn-group",
  role: "group",
  "aria-label": "Align Letterhead"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["max", "value"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "edit-image" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($setup.letterhead && $setup.store.edit_letterhead)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['Left', 'Center', 'Right'], (direction) => {
                return _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["btn btn-xs", $setup.letterhead.align == direction ? 'btn-secondary' : 'btn-default']),
                  onClick: $event => ($setup.letterhead.align = direction)
                }, _toDisplayString(direction), 11 /* TEXT, CLASS, PROPS */, _hoisted_5)
              }), 64 /* STABLE_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true),
        ($setup.letterhead && $setup.store.edit_letterhead)
          ? (_openBlock(), _createElementBlock("input", {
              key: 1,
              class: "ml-4 custom-range",
              type: "range",
              name: "image-resize",
              min: "20",
              max: $setup.range_input_field === 'image_width' ? 700 : 500,
              value: $setup.letterhead[$setup.range_input_field],
              onInput: _cache[0] || (_cache[0] = (e) => ($setup.letterhead[$setup.range_input_field] = parseFloat(e.target.value)))
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_6))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", null, [
        ($setup.letterhead && $setup.store.edit_letterhead)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "ml-2 btn btn-default btn-xs",
              onClick: $setup.upload_image
            }, _toDisplayString(_ctx.__("Change Image")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($setup.letterhead && $setup.store.edit_letterhead)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "ml-2 btn btn-default btn-xs btn-change-letterhead",
              onClick: $setup.change_letterhead
            }, _toDisplayString(_ctx.__("Change Letter Head")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($setup.letterhead)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "ml-2 btn btn-default btn-xs btn-edit",
              onClick: $setup.toggle_edit_letterhead
            }, _toDisplayString(!$setup.store.edit_letterhead ? _ctx.__("Edit Letter Head") : _ctx.__("Done")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (!$setup.letterhead)
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: "ml-2 btn btn-default btn-xs btn-edit",
              onClick: $setup.create_letterhead
            }, _toDisplayString(_ctx.__("Create Letter Head")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    ($setup.letterhead && !$setup.store.edit_letterhead)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: $setup.letterhead.content
        }, null, 8 /* PROPS */, _hoisted_7))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" <div v-show=\"letterhead && store.edit_letterhead\" ref=\"editor\"></div> "),
    ($setup.letterhead && $setup.store.edit_letterhead)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "edit-letterhead",
          style: _normalizeStyle({
				justifyContent: {
					Left: 'flex-start',
					Center: 'center',
					Right: 'flex-end',
				}[$setup.letterhead.align],
			})
        }, [
          _createElementVNode("div", _hoisted_8, [
            ($setup.letterhead.image)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("img", {
                    src: $setup.letterhead.image,
                    style: _normalizeStyle({
							width:
								$setup.range_input_field === 'image_width'
									? $setup.letterhead.image_width + 'px'
									: null,
							height:
								$setup.range_input_field === 'image_height'
									? $setup.letterhead.image_height + 'px'
									: null,
						})
                  }, null, 12 /* STYLE, PROPS */, _hoisted_10)
                ]))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn btn-default",
                  onClick: $setup.upload_image
                }, _toDisplayString(_ctx.__("Upload Image")), 1 /* TEXT */))
          ])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true)
  ]))
}